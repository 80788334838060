import React from 'react'
import Layout from '../components/Layout'
import { StartupSignupForm } from '../components/form/StartupSignupForm'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'

const StartupSignup = () => {
  return (
    <Layout
      justifyCenter
      title="Swarmia startup program"
      description="Apply to the Swarmia startup program"
    >
      <Row
        justifyContent="center"
        marginY={64}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box maxWidth={{ xs: undefined, md: 410 }} marginX={32}>
          <Intro />
        </Box>
        <Box maxWidth={{ xs: undefined, md: 500 }} marginX={32}>
          <StartupSignupForm />
        </Box>
      </Row>
    </Layout>
  )
}

const Intro = () => {
  return (
    <>
      <Box.h1>Apply to the Swarmia startup program</Box.h1>
      <Box.p>
        All startups that match the program requirements are eligible for a 50%
        discount.
      </Box.p>
    </>
  )
}

export default StartupSignup
