import { navigate } from 'gatsby'
import React, { useRef, useState } from 'react'
import { validateWorkEmailInput } from '../../formUtils'
import { HSFormField, sendFormToHS } from '../../hubspot'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Select from '../Select'
import CTABtn from './CTAButton'
import FormCheckboxContainer from './FormCheckboxContainer'

export function StartupSignupForm() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [website, setWebsite] = useState('')
  const [vc, setVc] = useState<StartupPartnerValue>('')

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const emailRef = useRef<HTMLInputElement>(null)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setErrorMsg(null)
    try {
      const formId = '9095fd4e-4149-45c3-ba56-0553111840b0'
      const fields: HSFormField[] = [
        { name: 'firstname', value: firstName },
        { name: 'lastname', value: lastName },
        { name: 'email', value: email },
        { name: 'website', value: website },
        { name: 'vc', value: vc },
      ]
      const res = await sendFormToHS(fields, formId)
      if (res.status === 200) {
        await navigate('/thank-you/?origin=startup-signup&target=qualified')
      } else {
        setErrorMsg(
          'There was an error submitting the form. Please try again. If the issue persists, contact us at startups@swarmia.com.',
        )
        window.Sentry?.captureException(JSON.stringify(res))
      }
    } catch (error) {
      window.Sentry?.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className="cta-form">
      <Box.form className="form" onSubmit={onSubmit}>
        <Row space={16}>
          <input
            type="text"
            placeholder="First name"
            required
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
          />
          <input
            type="text"
            placeholder="Last name"
            required
            onChange={e => setLastName(e.target.value)}
            value={lastName}
          />
        </Row>
        <input
          ref={emailRef}
          className="email"
          type="email"
          placeholder="Email"
          minLength={5}
          required
          onChange={e => {
            setEmail(e.target.value)
            validateWorkEmailInput(e.target)
          }}
          value={email}
        />
        <input
          className="website"
          type="text"
          placeholder="Website URL"
          minLength={5}
          required
          onChange={e => {
            setWebsite(e.target.value)
          }}
          value={website}
        />
        <Select
          classNamePrefix="demo-select"
          placeholder="VC"
          isSearchable={false}
          name="vc"
          required
          onChange={option => setVc(option?.value || '')}
          options={startupPartnerOptions}
        />
        <FormCheckboxContainer>
          <input
            type="checkbox"
            id="qualification"
            required
            onInvalid={e => {
              e.currentTarget.setCustomValidity(
                "Companies with fewer than ten developers are eligible for Swarmia's free plan. Companies with more than 50 developers are not eligible for the program.",
              )
            }}
            onClick={e => {
              if (e.currentTarget.checked) {
                e.currentTarget.setCustomValidity('')
              }
            }}
          />
          <label htmlFor="qualification">
            Your startup has 10-50 developers.
          </label>
        </FormCheckboxContainer>
        <FormCheckboxContainer>
          <input type="checkbox" id="consent" required />
          <label htmlFor="consent">
            I agree to receive email communication in accordance with
            Swarmia&#8217;s{' '}
            <a href="/privacy/" target="_blank">
              Privacy Policy
            </a>
            .
          </label>
        </FormCheckboxContainer>
        <Row display="relative">
          <CTABtn className="primary" disabled={loading}>
            Apply
          </CTABtn>
        </Row>
        {errorMsg && (
          <Row paddingTop={24}>
            <Error message={errorMsg} />
          </Row>
        )}
      </Box.form>
    </Box>
  )
}

function Error({ message }: { message: string }) {
  return (
    <Box
      backgroundColor="errorRed"
      color="white"
      paddingX={20}
      paddingY={12}
      borderRadius={12}
    >
      {message}
    </Box>
  )
}

const startupPartnerOptions = [
  { label: 'Alphalist', value: 'Alphalist' },
  { label: 'Alven', value: 'Alven' },
  { label: 'Antler', value: 'Antler' },
  { label: 'byFounders VC', value: 'byFounders VC' },
  { label: 'Cavalry Ventures', value: 'Cavalry Ventures' },
  { label: 'Dig Ventures', value: 'Dig Ventures' },
  { label: 'Five Elms Capital', value: 'Five Elms Capital' },
  { label: 'Founderpass', value: 'Founderpass' },
  { label: 'Lifeline Ventures', value: 'Lifeline Ventures' },
  { label: 'Molten Ventures', value: 'Molten Ventures' },
  {
    label: 'New Enterprise Associates: NEA',
    value: 'New Enterprise Associates: NEA',
  },
  { label: 'Northzone', value: 'Northzone' },
  { label: 'Susa Ventures', value: 'Susa Ventures' },
  { label: 'Underline Ventures', value: 'Underline Ventures' },
  { label: 'Wave Ventures', value: 'Wave Ventures' },
] as const

type StartupPartnerValue = (typeof startupPartnerOptions)[number]['value'] | ''
