import { useEffect, useState } from 'react'

// https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie-with-javascript/70118256#70118256
export function getCookie(name: string) {
  if (typeof document === 'undefined' || !name) {
    return
  }

  const nameEQ = name + '='
  for (const cookie of document.cookie.split('; ')) {
    if (cookie.indexOf(nameEQ) === 0) {
      const value = cookie.substring(nameEQ.length)
      return decodeURIComponent(value)
    }
  }
  return
}

const allConsentCategories = [
  'ad_storage',
  'analytics_storage',
  'functionality_storage',
  'personalization_storage',
  'security_storage',
  'ad_user_data',
  'ad_personalization',
] as const

/**
 * Note: this hook assumes that the browser emits an event with name
 * "cookiehubConsentUpdate" when the user's cookie consents are altered. As of
 * this writing, this is achieved by injecting the following script from GTM on
 * cookiehub_consent_update and cookiehub_ready triggers:
 *
 * (function() {
 *   event = new Event("cookiehubConsentUpdate");
 *   window.dispatchEvent(event);
 * })();
 *
 */
const cookiehubConsentEvent = 'cookiehubConsentUpdate' as const

export type ConsentCategory = (typeof allConsentCategories)[number]

export function useCookieConsents(): ConsentCategory[] {
  const initialCategories: ConsentCategory[] =
    process.env.NODE_ENV === 'production' ? [] : [...allConsentCategories]
  const [categories, setCategories] = useState(initialCategories)

  function onConsentEvent() {
    if (window.cookiehub && window.cookiehub.hasAnswered()) {
      const categories = allConsentCategories.filter(category =>
        window.cookiehub!.hasConsented(category),
      )
      setCategories(categories)
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.addEventListener(cookiehubConsentEvent, onConsentEvent, false)
      onConsentEvent()
      return () => {
        window.removeEventListener(cookiehubConsentEvent, onConsentEvent)
      }
    }
  }, [])

  return categories
}
